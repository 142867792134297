import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { firestore, remoteConfig } from "../../utils/firebase";
import ItemsList from "../../components/ItemsList";
import styles from "./styles.module.css";
import get from "lodash/get";
import headerLogo from "../../assets/img/logosmall.png";

class Explore extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, items: [], banner: null, style: {} };
  }
  componentDidMount() {
    this.getConfig();
    this.getItems();
  }
  getItems = async () => {
    try {
      const refs = await firestore.collection("items").get();
      const data = refs.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
      return this.setState({ items: data });
    } catch (error) {
      console.log(error);
    }
  };
  getConfig = async () => {
    await remoteConfig.fetchAndActivate();
    const banner = remoteConfig.getValue("banner");
    const homeStyle = remoteConfig.getValue("homeStyle");
    this.setState({
      loaded: true,
      banner: banner._value,
      style: JSON.parse(homeStyle._value)
    });
  };
  render() {
    const { process } = this.props;
    const { banner, items, style } = this.state;

    return (
      <div className={styles.container} style={get(style, "container", null)}>
        <div className={styles.wrapper}>
          <div className={styles.navBar}>
            <Link to="/" className={styles.brand}>
              <img src={headerLogo} alt="logo" />
            </Link>
            <div className={styles.buttons} style={{ display: "none" }}>
              <Link to="/">Home</Link>
              <Link to="/">Productos</Link>
              <Link to="/">Blog</Link>
              <Link to="/">Contacto</Link>
              <Link to="/">Nosotros</Link>
            </div>
          </div>
          <div className={styles.banner}>
            {banner ? <img src={banner} alt="logo" /> : null}
          </div>
          <ItemsList
            data={items}
            loadMore={this.loadMore}
            process={process}
            to={({ id }) => `/`}
          />
        </div>
        <div className={styles.footer}>
          <div className={styles.footerItem}>
            <FontAwesomeIcon icon={faEnvelope} />
            <div>
              <a href="mailto:contacto@senzafrettagourmet.com">
                contacto@senzafrettagourmet.com
              </a>
            </div>
          </div>
          <div className={styles.footerItem}>
            <FontAwesomeIcon icon={faPhone} />
            <div>
              <a href="tel:4625905193">4625905193</a>,
              <a href="tel:5584056397"> 5584056397</a>
            </div>
          </div>
          <div className={styles.footerItem}>
            <FontAwesomeIcon icon={faFacebook} />
            <div>
              <a href="https://www.facebook.com/SenzaFrettaGourmet">
                @SenzaFrettaGourmet
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Explore.defaultProps = {
  items: [],
  cartItems: []
};

export default Explore;
