import React from "react";
import { Route, Switch, Link, Redirect } from "react-router-dom";
import { auth } from "../../utils/firebase";
import Items from "../Items";
import ItemsForm from "../ItemsForm";
import styles from "./styles.module.css";

export default class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user: null };
    this.unsubscriber = auth.onAuthStateChanged(user => {
      if (user) {
        this.setState({ user });
      } else {
        this.props.history.push("/login");
      }
    });
  }
  componentWillUnmount() {
    this.unsubscriber();
  }
  logout = async () => {
    await auth.signOut();
    this.props.history.push("login");
  };
  render() {
    if (!this.state.user) {
      return null;
    }

    return (
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <Link to="/admin/items">Items</Link>
          <button onClick={this.logout}>Logout</button>
        </div>
        <div className={styles.wrapper}>
          <Switch>
            <Route exact path="/admin/items" component={Items} />
            <Route exact path="/admin/items/new" component={ItemsForm} />
            <Route exact path="/admin/items/:id" component={ItemsForm} />
            <Redirect from="/admin/" to="/admin/items" />
          </Switch>
        </div>
      </div>
    );
  }
}
