import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import "./utils/firebase";
import Home from "./containers/Home";
import Items from "./containers/Items";
import Login from "./containers/Login";
import Admin from "./containers/Admin";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/articulos" component={Items} />
          <Route path="/admin" component={Admin} />
          <Route exact path="/login" component={Login} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
