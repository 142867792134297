import React from "react";
import { Link } from "react-router-dom";
import noop from "lodash/noop";
import get from "lodash/get";
import styles from "./styles.module.css";

function trunc(string, n) {
  if (!string) {
    return "";
  }
  if (string.length <= n) {
    return string;
  }
  var truncated = string.substr(0, n);
  if (string.charAt(n) === " ") {
    return truncated;
  }
  return truncated.substr(0, truncated.lastIndexOf(" "));
}

export default function Item({ data, to }) {
  const { id, name, listImage, price } = data;
  return (
    <Link key={id} className={styles.item} to={to(data)}>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={listImage} alt="" />
      </div>
      <div className={styles.itemInfo}>
        <div className={styles.itemInfoWrapper}>
          <div className={styles.itemName}>{trunc(name, 80)}</div>
          <div className={styles.itemPrice}>
            ${parseFloat(price).toFixed(2)}
          </div>
        </div>
      </div>
    </Link>
  );
}

Item.defaultProps = {
  data: {
    id: null,
    name: "",
    price: 0,
    images: []
  },
  to: noop
};
