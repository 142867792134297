import React from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import noop from "lodash/noop";
import style from "./style.module.css";

class EditorCompoenent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorState: EditorState.createEmpty() };
    this.parse();
  }
  componentDidUpdate(prev) {
    if (!prev.value && this.props.value) {
      this.parse();
    }
  }
  parse = () => {
    try {
      const json = JSON.parse(this.props.value);
      const contentState = convertFromRaw(json);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({ editorState });
    } catch (err) {
      this.setState({ editorState: EditorState.createEmpty() });
    }
  };
  onChange = editorState => {
    this.setState({
      editorState
    });

    const { id } = this.props;
    const value = JSON.stringify(convertToRaw(editorState.getCurrentContent()));

    this.props.onChange({
      id,
      value,
      type: "richText"
    });
  };

  render() {
    const { editorState } = this.state;
    const { toolbarHidden, readOnly, label } = this.props;
    return (
      <div className={style.inputContainerStyle}>
        {label ? (
          <label className={style.inputLabelStyle}>{label}</label>
        ) : null}
        <Editor
          toolbarHidden={toolbarHidden}
          readOnly={readOnly}
          editorState={editorState}
          onEditorStateChange={this.onChange}
          wrapperClassName={readOnly ? "" : style.wrapper}
          toolbarClassName={style.toolbar}
          editorClassName={readOnly ? style.editorReadOnly : style.editor}
          placeholder="Descripción..."
        />
      </div>
    );
  }
}

EditorCompoenent.defaultProps = {
  onSave: noop
};
export default EditorCompoenent;
