import React from "react";
import style from "./styles.module.css";

export default function Text(props) {
  const { id, label, placeholder, value, onChange } = props;
  return (
    <div className={style.inputContainerStyle}>
      <label htmlFor={id} className={style.inputLabelStyle}>
        {label}
      </label>
      <input
        type="text"
        className={style.inputStyle}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={e =>
          onChange({ id, value: e.target.value, type: "text", event: e })
        }
      />
    </div>
  );
}
