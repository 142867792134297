import firebase from "@firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import "@firebase/storage";
import "@firebase/performance";
import "@firebase/analytics";
import "@firebase/remote-config";

firebase.initializeApp({
  apiKey: "AIzaSyBwS0oygQgQMpxasgp-zVg_DecJlEq9GRI",
  authDomain: "senzafrettagourmet-45e36.firebaseapp.com",
  databaseURL: "https://senzafrettagourmet-45e36.firebaseio.com",
  projectId: "senzafrettagourmet-45e36",
  storageBucket: "senzafrettagourmet-45e36.appspot.com",
  messagingSenderId: "765247900177",
  appId: "1:765247900177:web:cac54676c8cce56af43fb0",
  measurementId: "G-7PWKTL8QFZ"
});

export const app = firebase;
export const db = firebase.firestore();
export const firestore = db;
export const auth = firebase.auth();
export const storage = firebase.storage().ref();

firebase.performance();
export const analytics = firebase.analytics();
export const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  minimumFetchIntervalMillis: 30000
};
