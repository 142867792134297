import React from "react";
import get from "lodash/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import style from "./styles.module.css";

export default class Image extends React.Component {
  state = { img: "" };
  componentDidMount() {
    const type = get(this.props, "value.type", null);
    if (type) {
      this.readFile(this.props.value);
    } else {
      this.setState({ img: this.props.value });
    }
  }
  componentDidUpdate(prevProps) {
    const type = get(this.props, "value.type", null);
    const currentName = get(this.props, "value.name", null);
    const previusName = get(prevProps, "value.name", null);

    if (type && currentName !== previusName) {
      this.readFile(this.props.value);
    }
    if (this.props.value !== prevProps.value) {
      this.setState({ img: this.props.value });
    }
  }
  readFile = file => {
    const reader = new FileReader();
    reader.onload = e => this.setState({ img: e.target.result });
    reader.readAsDataURL(file);
  };
  openFileSelector = e => {
    e.stopPropagation();
    this.refs.inputFile.click();
  };
  change = e => {
    const { id, onChange } = this.props;
    const value = e.target.files[0];

    if (e.target.files.length) {
      onChange({ id, value, type: "image", event: e });
    }
  };
  render() {
    const { id, label } = this.props;
    return (
      <div className={style.inputContainerStyle}>
        <label htmlFor={id} className={style.inputLabelStyle}>
          {label}
        </label>
        <div className={style.imageContainer}>
          {this.state.img ? (
            <img className={style.image} src={this.state.img} alt="" />
          ) : null}
          <button
            type="button"
            className={style.changeButton}
            onClick={this.openFileSelector}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </div>
        <input
          type="file"
          style={{ display: "none" }}
          id={id}
          onChange={this.change}
          ref="inputFile"
        />
      </div>
    );
  }
}

Image.defaultProps = {
  value: ""
};
