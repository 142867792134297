import React from "react";
import { Link } from "react-router-dom";
import { firestore, remoteConfig } from "../../utils/firebase";
import ItemsList from "../../components/ItemsList";
import styles from "./styles.module.css";
import get from "lodash/get";

class Explore extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, items: [], banner: null, style: {} };
  }
  componentDidMount() {
    this.getConfig();
    this.getItems();
  }
  getItems = async () => {
    try {
      const refs = await firestore.collection("items").get();
      const data = refs.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
      console.log(data);
      return this.setState({ items: data });
    } catch (error) {
      console.log(error);
    }
  };
  getConfig = async () => {
    await remoteConfig.fetchAndActivate();
    const banner = remoteConfig.getValue("banner");
    const homeStyle = remoteConfig.getValue("homeStyle");
    this.setState({
      loaded: true,
      banner: banner._value,
      style: JSON.parse(homeStyle._value)
    });
  };
  render() {
    const { process } = this.props;
    const { items, style } = this.state;

    return (
      <div className={styles.container} style={get(style, "container", null)}>
        <div className={styles.header}>
          <Link className={styles.defaultButton} to="/admin/items/new">
            New Item
          </Link>
        </div>
        <div className={styles.wrapper}>
          <ItemsList
            data={items}
            loadMore={this.loadMore}
            process={process}
            to={({ id }) => `/admin/items/${id}`}
          />
        </div>
      </div>
    );
  }
}

Explore.defaultProps = {
  items: [],
  cartItems: []
};

export default Explore;
