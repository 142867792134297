import React from "react";
import Item from "../Item";
import styles from "./styles.module.css";

export default function ItemsList(props) {
  const { data, to } = props;

  return (
    <div className={styles.container}>
      <div className={styles.masonry}>
        {data.map(item => (
          <div key={item.id} className={styles.itemContainer}>
            <Item data={item} to={to} />
          </div>
        ))}
      </div>
    </div>
  );
}

ItemsList.defaultProps = {
  data: []
};
