import React from "react";
import { Link } from "react-router-dom";
import { firestore, storage } from "../../utils/firebase";
import Editor from "../../components/Input/Editor";
import Text from "../../components/Input/Text";
import Image from "../../components/Input/Image";
import styles from "./styles.module.css";

class Explore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      process: true,
      error: null,
      data: {
        name: "",
        price: 0,
        shortDescription: "",
        description: "",
        listImage: null
      }
    };

    if (this.props.match.params.id) {
      this.ref = firestore.collection("items").doc(this.props.match.params.id);
      this.getItem();
    } else {
      this.ref = firestore.collection("items").doc();
      this.setState({ process: false });
    }
  }
  getItem = async id => {
    try {
      const ref = await this.ref.get();
      if (ref.exists) {
        return this.setState({
          data: ref.data(),
          process: false,
          error: false,
          status: false
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  onSubmit = async e => {
    try {
      e.preventDefault();
      let data = this.state.data;

      if (this.state.data.listImage instanceof File) {
        const listImageRef = storage.child(`items/${this.ref.id}/listImage`);
        const snapshot = await listImageRef.put(this.state.data.listImage, {
          cacheControl: "public,max-age=300",
          gzip: true
        });
        const downloadURL = await snapshot.ref.getDownloadURL();
        data.listImage = downloadURL;
      }

      await this.ref.set(data, { merge: true });
      this.props.history.push(`/admin/items/${this.ref.id}`);
      this.setState({ process: false, error: false, status: "saved" });
    } catch (error) {
      console.log(error);
    }
  };
  onDelete = async e => {
    try {
      e.preventDefault();
      await this.ref.delete();
      this.props.history.push(`/admin/items`);
    } catch (error) {
      console.log(error);
    }
  };
  onChange = ({ id, value }) => {
    this.setState(state => ({
      ...state,
      data: { ...state.data, [id]: value },
      process: false,
      error: false,
      status: false
    }));
  };
  render() {
    const { process, error, data } = this.state;
    const { name, price, shortDescription, description, listImage } = data;

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <Link className={styles.defaultButton} to="/admin/items">
            Go Back
          </Link>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.inputsContainer}>
            <div className={styles.name}>
              <Text
                id="name"
                label="Name"
                value={name}
                onChange={this.onChange}
              />
            </div>
            <div className={styles.price}>
              <Text
                id="price"
                label="Price"
                value={price}
                onChange={this.onChange}
              />
            </div>
            <div className={styles.shortDescription}>
              <Text
                id="shortDescription"
                label="Short Description"
                value={shortDescription}
                onChange={this.onChange}
              />
            </div>
            <div className={styles.image}>
              <Image
                id="listImage"
                label="Image"
                value={listImage}
                onChange={this.onChange}
              />
            </div>
          </div>
          <Editor
            id="description"
            label="Description"
            value={description}
            onChange={this.onChange}
          />
        </div>
        <div className={styles.footer}>
          <button className={styles.footerButton} onClick={this.onDelete}>
            Delete
          </button>
          <button
            className={styles.footerButton}
            onClick={this.onSubmit}
            style={{
              color:
                this.state.status === "saved" ? "#48B041" : "rgba(0, 0, 0, 0.8)"
            }}
          >
            {this.state.status === "saved" ? "Saved!" : "Save"}
          </button>
        </div>
      </div>
    );
  }
}

Explore.defaultProps = {
  items: [],
  cartItems: []
};

export default Explore;
