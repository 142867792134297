import React from "react";
import styles from "./styles.module.css";
import logo from "../../assets/img/logo.png";
import { auth } from "../../utils/firebase";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.email = React.createRef();
    this.password = React.createRef();
    this.state = {
      process: null,
      error: null
    };
  }
  submit = async e => {
    e.preventDefault();
    const email = this.email.current.value;
    const password = this.password.current.value;
    this.setState({ error: null, process: true }, async () => {
      try {
        await auth.signInWithEmailAndPassword(email, password);
        this.props.history.push("/admin");
      } catch (error) {
        this.setState({ error });
      }
    });
  };
  render() {
    const { error } = this.state;
    return (
      <div className={styles.container}>
        <img src={logo} className={styles.logo} alt="catedrapp" />
        <form className={styles.form} onSubmit={this.submit}>
          <input
            ref={this.email}
            className={styles.input}
            placeholder="Email"
          />
          <input
            ref={this.password}
            className={styles.input}
            placeholder="Password"
            type="password"
          />
          <button className={styles.button} type="submit">
            INICIAR
          </button>
          <div className={styles.error}>{error && error.message}</div>
        </form>
      </div>
    );
  }
}
